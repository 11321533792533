.App {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
  overflow-x: hidden;
}

.page {
	height: 100vh;
	width: 100vw;
	display: flex;
	font-size: 5vmin;
	font-family: 'Courier New', Courier, monospace;
	align-content: center;
	justify-content: center;
  margin: 0;
	position: relative;
}

.spacer {
	height: 100vmin;
}

.divider {
	height: fit-content;
	display: flex;
	flex-wrap: wrap;
	z-index: 10;
}

html {
	scroll-behavior: smooth;
}

img {
	object-fit: cover;
	max-width: 100%;
	min-height: 100%;
}

a {
	text-decoration: underline;
	color: white;
}

/* first page */

#page1 {
	background-color: #424B54;
	display: flex;
	flex-direction: column;	
	align-items: center;	
}

#greeting {
	color: white;
	text-align: center;
	width: 75vw;
	font-size: calc(1.8rem + 1vmax);
}

.down-arrow-wrapper {
	position: absolute;
	top: 80vh;
	text-align: center;
	color: #8898a7;
	font-size: calc(0.5rem + 1vmax);
	transform: translate(-50%, 0);
}
  
.down-arrow {
	font-size: calc(2rem + 1vmax);
}

/* second page */

#page2 {
	display: flex;
	flex-wrap: wrap;
	position: relative;

	background-color: #f5f5db;
	height: fit-content;
	padding-bottom: 20vh;
	padding-top: 10vw;
}

.intro_container {
	text-align: left;
	margin-left: 5vmin;
	margin-right: 5vmin;
	margin-top: min(40vmin, 50%);
	margin-bottom: 15vmax;
	height: fit-content();
}

#page2_header_name {
	font-size: calc(1.4rem + 1vmax);
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	margin-bottom: 2%;
}

#page2_header_name2 {
	margin-right: 1ch;
}

#page2_subheader_title {
	font-size: calc(1rem + 1vmax);
}

#page2_subheader {
	font-size: calc(1rem + 1vmax);
}

#page2_info {
	width: min(45rem, 75vw);
	font-size: calc(0.6rem + 1vmax);
}

#page2_infobody{
	height: calc(30vh - 30vw);
	min-height: 15vh;
}

.printing_container {
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
}

.selfie_container {
	position: relative;
	width: 90%;
	height: 80%;
	top: 5%;
	margin: auto;
	overflow: hidden;
	background-color: black;

	outline-style: double;
	outline-color: black;
	outline-width: 2px;
	z-index: 2;

}

#headshot {
	position: absolute;
	bottom: 0px;
	opacity: 0;
	animation-duration: 2s;
	animation-name: fadein;
	animation-delay: 2.5s;
	animation-fill-mode: forwards;	
}

#headshot:after {
	opacity: 1;
}

@keyframes fadein {
	0% {opacity: 0;}

	100% {opacity: 1;}
}

#printer_top {
	margin-top: 20%;
	background-color: #cda065;
	width: calc(min(60vw, 40rem) + 60px);
	height: 100px;
	border-radius: 4px;
	position: relative;
	bottom: 40px;
}

#printer_top_slot {
	background-color: black;
	width: calc(min(60vw, 40rem) + 20px);
	height: 5px;
	z-index: 2;
	border-radius: 4px;
	position: relative;
	bottom: 45px;
}

#printer_bottom_slot {
	background-color: black;
	width: calc(min(60vw, 40rem) + 20px);
	height: 5px;
	z-index: 0;
	border-radius: 4px;
	position: relative;
	bottom: 47px;
}

#printer_bottom {
	background-color: #cda065;
	width: calc(min(60vw, 40rem) + 60px);
	height: 100px;
	border-radius: 4px;
	position: relative;
	bottom: 40px;
	margin-top: -100px;
}

.polaroid_container {
	overflow: clip;
	overflow-clip-margin: 50px;
}

.printing_animation {
	width: min(60vw, 40rem);
	height: max(17em, 40vw);
	background-color: white;   
	position: relative;
	animation-duration: 4s;
	animation-direction: forwards;
	animation-timing-function: ease-out;
	box-shadow: 0px 0px 30px 3px;
}

@keyframes printing {
	0% {
		bottom: 140%;
	}   
	9% {
		bottom: 100%;
	}
	11%{
		bottom: 90%;
	}   

	20% {
		bottom: 89%;
	}
	22%{
		bottom: 80%;
	} 
	
	40% {
		bottom: 78%;
	}
	42%{
		bottom: 70%;
	}

	60% {
		bottom: 69%;
	}

	100%{
		bottom: 0%;
	}

}



/* page 3 */

#page3{
	background-color: #553825;
	color: white;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: fit-content;
	padding-top: 30vh;
	padding-bottom: 30vh;
}

.timeline_entry {
	display: flex;
	flex-direction: row;
}

.timeline_container {
	display: flex;
	flex-direction: row;
	width: fit-content;
}

.timeline_bar {
	display: flex;
	margin-right: 3vw;
	height: 100%;
	min-height: 200px;
	width: 0.5vw;
	background-color: white;
	position: relative;
	align-items: center;
	flex-direction: column;
}

.timeline_date {
	margin-top: 1%;
	font-size: calc(1rem + 1vmax);
	width: 15vw;
	color: white;
	text-align: right;
	margin-right: 2em;
}

.timeline_dot {
	background-color: #553825;
	border-radius: 50%;
	border-style: solid;
	outline: white solid 10px;
	width: 2vmax;
	height: 2vmax;
}

.timeline_dot_current {
	background-color: #553825;
	border-radius: 20%;
	border-style: solid;
	outline: white solid 10px;
	width: 2vmax;
	height: 2vmax;
}

.timeline_dot_year {
	position: relative;
	top: 4%;
	rotate: 45deg;
	border-style: solid;
	border-bottom: white;
	border-right: white;
	border-width: 10px;
	width: 2vmax;
	height: 2vmax;
}

.timeline_stopper {
	position: absolute;
	bottom: 0px;
	width: 3em;
	height: 0.5vw;
	background-color: white;
}

#page3_header{
	font-size: calc(1.2rem + 1.5vmax);
	margin-bottom: 10%;
	text-align: center;
	width: min(80%, 90vw);
	height: calc(40vh - 30vw);
}

.page3_section {
	width: 75vw;
	height: fit-content;
}

.page3_header2 {
	width: fit-content;
	font-size: calc(1rem + 1vmax);
	margin-left: 1em;
	margin-bottom: 2em;
}

.page3_subheader{
	margin-top: 0.5em;
	font-size: calc(0.8rem + 1vmax);
}

.page3_body {
	font-size: calc(0.6rem + 1vmax);
	margin-bottom: 10%;
}

/* page 4 */

#page4 {
	background-color: #1f1d1b;
	color: white;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: calc(50rem + 30vh);
	align-items: center;
}

.page4_header {
	font-size: calc(1.2rem + 1.5vmax);
	margin-bottom: 10vmin;
	text-align: center;
	width: min(80%, 90vw);
	height: calc(20vh - 30vw);
}

.contacts_sec1 {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-around;
	margin: 1em;
	width: 75vw;
	font-size: calc(1rem + 1.5vmax);
}

.contacts_sec2 {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin: 1em;
	width: 75vw;
}

.link {
	margin: 3%;
}


/* custom dividers */

    /* page 1 */

    .custom-shape-divider-bottom-1725843514 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-bottom-1725843514 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 200px;
    }

    .custom-shape-divider-bottom-1725843514 .shape-fill {
        fill: #EBCFB2;
    }


    /* page 2 */

    .custom-shape-divider-top-1725843655 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-top-1725843655 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 200px;
    }

    .custom-shape-divider-top-1725843655 .shape-fill {
        fill: #EBCFB2;
    }



    .custom-shape-divider-bottom-1725844347 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }

    .custom-shape-divider-bottom-1725844347 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 130px;
    }

    .custom-shape-divider-bottom-1725844347 .shape-fill {
    fill: #cda065;
    }


    /* page 3 */

    .custom-shape-divider-top-1725844454 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }

    .custom-shape-divider-top-1725844454 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 130px;
    }

    .custom-shape-divider-top-1725844454 .shape-fill {
        fill: #cda065;
    }	

		/* page 4 */

		.custom-shape-divider-top-1726513486 {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			overflow: hidden;
			line-height: 0;
			background-color: #3e2c1f;
		}
		
		.custom-shape-divider-top-1726513486 svg {
				position: relative;
				display: block;
				width: calc(100% + 1.3px);
				height: 126px;
		}
		
		.custom-shape-divider-top-1726513486 .shape-fill {
				fill: #553825;
		}

		.custom-shape-divider-top-1726513587 {
			position: absolute;
			top: 126px;
			left: 0px;
			width: 100%;
			overflow: hidden;
			line-height: 0;
		}
		
		.custom-shape-divider-top-1726513587 svg {
				position: relative;
				display: block;
				width: calc(100% + 1.3px);
				height: 126px;
		}
		
		.custom-shape-divider-top-1726513587 .shape-fill {
				fill: #3e2c1f;
		}


.version {
	position: absolute;
	bottom: 10%;
	color: #4f4f4f;
	font-size: 20px;
}